@use "sass:math";
@import '../../../styles/variables';

.root {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  color: black;
  background-color: white;

  >span,
  ._logo {
    margin: 0 $componentXPadding;
    display: flex;
  }

  svg {
    cursor: pointer;
  }


  ._menu-items {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: row;

    span,
    a {
      font-size: 1.5rem;
    }

    a {
      font-weight: 500;
      margin: 0 1.25rem;
      color: inherit;
      text-decoration: none;
      height: 100%;
      align-items: center;
      display: flex;
    }

    >span {
      transition: opacity 250ms ease-in;
    }

    &:hover {
      >span {
        transition: opacity 250ms ease-out;
      }

      &>span:not(:hover) {
        opacity: 0.5;
      }
    }
  }

  // MOBILE
  @media screen and (max-width: $desktopBreakpoint) {
    justify-content: flex-end;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 10;

    >span {
      padding-top: 0;
      margin-left: 0;
      margin: 0;

      ._logo {
        margin-left: 20px;
      }

      ._close {
        background-color: white;
        padding: 0 math.div($padding, 2);

        height: 40px;
      }
    }

    ._menu-items {
      display: grid;
      @include threeColumnLayout_8bar_auto_auto;
      width: 100vw;
      z-index: 9;
      margin-right: 0;

      ._logo {
        cursor: pointer;
        margin-left: 0;
      }
    }
  }

  ._mobile-menu-panel {
    display: none;
    position: absolute;
    flex-direction: column;
    width: 105vw;
    min-height: calc(100vh);
    max-height: calc(100vh);
    height: auto;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 10;

    &._open {
      display: flex;
    }

    ._top {
      padding: 0 $padding;
      display: flex;
      height: 70px;
      min-height: 70px;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      z-index: 1999990;

      >span {
        font-weight: 500;
        z-index: 100000;
      }

      svg {
        g {
          fill: white;
        }
      }
    }

    ._content {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 100px 10vw;
      overflow-x: hidden;
      overflow-y: auto;

      a {
        margin-bottom: 40px;
        text-decoration: none;
        color: black;
        font-style: normal;
        font-weight: normal;
        font-size: 3rem;
        line-height: 31px;
        text-align: center;
      }

      a:active {
        color: $blue;
      }
    }
  }
}