@import '../../../styles/variables';
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  > span {
    height: 110%;
    width: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .SC__debug & {
    border: 1px solid pink;
  }
}
