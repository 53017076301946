.sanity-error-page {
    background: var(--white);
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sanity-error-page div {
    margin: 3.2rem 1.6rem 6.4rem 1.6rem;
}

.sanity-error-page img {
    display: block;
    max-width: 48rem;
    width: 100%;
    height: auto;
}