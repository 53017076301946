@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 70px;
  height: 100%;
  z-index: 20;
  svg {
    path {
      stroke: black;
    }
  }
}
