@import '../../../styles/variables';

.root {
  position: fixed;
  flex-direction: column;
  bottom: 20px;
  left: 20px;
  display: flex;
  width: 80vw;
  max-width: 650px;
  height: auto;
  min-height: 300px;
  justify-content: space-between;
  color: #000;
  background-color: #fff;
  z-index: 999;
  padding: 47px;
  box-sizing: border-box;
  border: 1px solid #000;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 155%;
  }

  a {
    color: lightskyblue;
    text-decoration: none;
    font-size: 16px;
  }

  @media screen and (max-width: $largePhoneBreakpoint) {
    width: calc(100% - 10px);
    padding: 20px;
    left: 10px;

    p,
    a {
      font-size: 12px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
    > span {
      margin-right: 40px;
    }

    > button {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      background-color: transparent;
      margin-right: 2rem;
    }

    @media screen and (max-width: $largePhoneBreakpoint) {
      margin-top: 10px;
      > span {
        margin-right: 20px;
      }
    }
  }

  .settings {
    display: none;
    flex-direction: column;

    min-height: 10px;
    margin-top: 40px;

    &.active {
      display: flex;
    }
  }
}

.inputComp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: $largePhoneBreakpoint) {
    margin-bottom: 10px;

    .right {
      flex: 10;
    }
  }

  .left {
    position: relative;
    flex: 1;

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 16px;
      height: 16px;
      min-width: 16px;
      border: 1px solid black;
      background-color: white;
      box-sizing: border-box;
    }

    &.active {
      &:after {
        background-color: black;
      }
    }
  }

  .right {
    flex: 6;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    text-align: left;

    h5 {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      width: 80%;
      font-family: 'Lato', sans-serif;

      svg {
        margin-left: 5px;
      }
    }

    p {
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 1.9rem;
      letter-spacing: 0.03em;
      text-transform: none;
      font-weight: 300;
    }

    @media screen and (max-width: $largePhoneBreakpoint) {
      flex: 10;

      h5 {
        font-size: 10px;
        line-height: 12px;
      }

      p {
        margin-top: 5px;
        font-size: 10px;
        line-height: 12px;
        // width: calc(100% + 30px);
        // transform: translate3d(-30px, 0, 0);
      }
    }

    @media screen and (max-height: $desktopBreakpoint) {
    }
  }
}
