@import '../../../styles/variables';
.root {
  background-color: $gray;
  color: white;
  // width: 100vw;
  @include full-width;
  @include oneColumnLayout;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 0;
  img {
    width: 47px;
  }
  a {
    display: block;
    text-decoration: none;
    width: fit-content;
    transition: all 250ms ease-out;
    position: relative;
    color: white;
    &:hover {
      color: $green;
    }
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .wrapper-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    @include max-plug-width;
    width: 100%;
    height: 100%;
  }
  .wrapper-inner {
    position: relative;
    display: grid;
    flex-direction: column;
    width: 100%;
    @include threeRowAutoLayout;
    @media screen and (max-width: $desktopBreakpoint) {
      display: block;
    }
  }
  .newsletter {
    background-color: white;
    .inner {
      display: flex;
      background-color: #a9fff7;
      width: $w_10_ColumnWidth;
    }
    .inputWrapper {
      border: 1px black solid;
    }
  }
  ._top {
    flex: 6;
    display: grid;
    // @include twoRowLayout_100_and_16bar_width;
    grid-template-columns: minmax(70px, 7vw) minmax(170px, $w_2_ColumnWidth) minmax(
        auto,
        $w_9_ColumnWidth
      ) minmax(110px, $w_3_ColumnWidth);
    grid-template-rows: auto;
    column-gap: $contentColumnSpace * 2;
    // @include paddingY;
    padding: 3rem 0 12rem $contentColumnWidth;
    @media screen and (max-width: $desktopBreakpoint) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem $contentColumnWidth 6rem $contentColumnWidth;
    }
  }
  .bottom {
    position: relative;
    background-color: white;
    height: 80px;
    @media screen and (max-width: $desktopBreakpoint) {
      padding: 3rem 0;
    }
    a {
      color: black;
      &:hover {
        color: $green;
      }
    }
    &:before {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      top: 0;
      background-color: white;
      z-index: 0;
    }
    .inner {
      margin-left: auto;
      margin-right: auto;
      color: black;
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media screen and (max-width: $desktopBreakpoint) {
        flex-direction: column;
        // align-items: start;
        padding: 0 $contentColumnWidth * 0.5;
      }
      a:hover {
        text-decoration: underline;
      }
      > hr {
        margin: 1.6rem;
        @media screen and (max-width: $desktopBreakpoint) {
          margin: 1.6rem 0;
        }
      }
      p,
      hr {
        z-index: 1;
      }
    }
    ._cookie {
      margin-bottom: 5px;
      &__middle {
        position: relative;
        margin: 0 10%;
        background-color: gray;
        &:before {
          content: '';
          position: absolute;
          width: 2px;
          height: 2px;
          border-radius: 1px;
          background-color: red;
          left: -25px;
          top: 9px;
        }
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 1px;
          background-color: white;
          right: -25px;
          top: 20px;
        }
      }
    }
  }
}

.footerColumn {
  > div {
    p {
      font-size: 1.6rem;
      line-height: 160%;
      margin-bottom: 0.02rem;
    }
  }
  // TODO: fix footer for mobil
  @media screen and (max-width: $desktopBreakpoint) {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      display: none;
    }
    margin-top: 20px;
    h4 {
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 22px;
      padding-left: 10px;
    }
    a {
      margin-bottom: 1rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-align: center;
      &:after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        width: 85%;
        bottom: 0;
        left: 0;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 1.6rem;
      }
      a:nth-child(-n + 2) {
        &:before {
          content: '';
          position: absolute;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.2);
          width: 85%;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
