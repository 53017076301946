@import '../../../styles/variables';
.root {
  position: relative;
  height: 100%;
  z-index: 10;
  &:hover {
    cursor: pointer;
    ._routes {
      visibility: visible;
    }
  }
  ._label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
    height: 100%;
    a {
      text-decoration: inherit;
    }
  }
  ._routes {
    position: absolute;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    top: 70px;
    padding: 2.88rem;
    transform: translate3d(-20%, 0, 0);
    background-color: $gray;
    z-index: 1;
    > div {
      font-size: 2.2rem;
      display: flex;
      color: white;
      transition: opacity 150ms ease-in;
      > span {
        font-size: 1rem;
        padding-left: 0.64rem;
      }
    }
    div:not(:last-child) {
      padding-bottom: 2.88rem;
    }
    &:hover {
      div:not(:hover) {
        opacity: 0.3;
        transition: opacity 250ms ease-out;
      }
    }
  }
}
