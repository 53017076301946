#gillgruppen-container {
  &.SC__print {
    ul {
      list-style-type: none;
    }

    button,
    header,
    footer,
    #footer,
    aside,
    form {
      display: none !important;
    }

    article {
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    a:after {
      content: '(' attr(href) ')';
    }

    .page-header__menu-items {
      display: none;
    }

    .priceList {
      width: 100%;
    }
  }
}
