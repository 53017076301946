@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital@1&display=swap');
$max-text-width: 760px;
div,
span,
button,
input,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  // letter-spacing: 0.05em;
  line-height: 160%;
  white-space: break-spaces;
  line-break: auto;
  &::placeholder {
    font-family: 'Lato', sans-serif;
    font-weight: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    // letter-spacing: 0.05em;
  }
}

// TODO: make text great again
.italic_garamond {
  font-family: 'EB Garamond', serif;
  font-weight: 400;
  font-size: 32px;
  font-style: italic;
}

div,
span {
  font-weight: 300;
}

a,
p,
h1,
h2,
h3,
h4 {
  max-width: $max-text-width;
}

p {
  // letter-spacing: 0.07;
}

a,
p {
  font-weight: 300;
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.bold {
  font-weight: 600;
}

// Font classes
.h4-strong {
  font-weight: 600;
}

ul,
li {
  list-style-type: none;
}

code {
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
}

// EstaItalic
