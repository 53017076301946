#loading {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  background-color: transparent;
  align-self: center;
  &.white {
    width: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
    right: 20px;
    top: 23px;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.in-view {
  .BrandList {
    .wrapper-inner {
      h4,
      div {
        transform: translate3d(0, 0, 0) !important;
        opacity: 1 !important;
      }
    }
  }
}
