@import '../../../styles/variables';
.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $desktopBreakpoint) {
    padding-right: 0;
  }

  button {
    text-transform: uppercase;
    margin: 0 0.5em;
    background-color: transparent;
    font-weight: 500;

    &:hover,
    &.active {
      color: $blue;
    }
  }
  button:last-child:before {
    margin-right: 0.5em;
    color: black;
    content: '/\00a0';
  }
}
