.page-header {
    z-index: 2;
    .bold {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: rgba(#e3e8eb, 0.5);
        margin-left: 2rem;
    }
    &.inverted {
        .bold {
            background-color: rgba(#e3e8eb, 0.3) !important;
        }
    }
    @media screen and (max-width: $desktopBreakpoint) {
        .label {
            color: white;
        }
        .route {
            color: white;
            background-color: black;
            h3 {
                color: white;
            }
        }
    }
    @media screen and (min-width: $desktopBreakpoint) {
        .label {
            color: black;
        }
        .route {
            color: black;
            background-color: white;
            h3 {
                color: black;
            }
        }
    }
    &.inverted {
        color: white;
        background-color: black;
        .label {
            color: white;
        }
        .route {
            color: white;
            background-color: black;
            h3 {
                color: white;
            }
        }
    }
}