@import './404.scss';
@import './fonts.global';
@import './mixins';
@import './variables';
@import './simpleColumns';
@import './header';
@import './footer';
@import './tweaks';
@import './animations';
@import './debug';
* {
  -webkit-print-color-adjust: exact;
}

html {
  font-size: $font-size;
  width: auto;
  overflow-x: hidden;
}

html,
body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

.gillgruppen-container {
  -webkit-font-smoothing: antialiased;
  font-weight: var(--text-font-weight);
  font-family: var(--text-font-family);
  min-height: 100%;
  color: var(--fg1);
  background: var(--bg1);

  width: 100%;
  overflow: hidden;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    font-size: 1.6rem;
    font-family: 'Lato', sans-serif;
    line-height: 2.5rem;
  }
}

button {
  font-family: 'Roboto Mono';
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
}

input,
button,
submit {
  border: none;
}

button,
submit {
  cursor: pointer;
  height: 4rem;
}

label {
  font-family: 'Roboto Mono';
  font-weight: 400;
  letter-spacing: 0.02rem;
}

a {
  color: $green;
}

#footer {
  z-index: 10;
}

.price-footer {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.4rem;
  opacity: 0.4;
  height: 120px;
  width: 100vw;
  max-width: 600px;
  @media screen and (max-width: $desktopBreakpoint) {
    width: unset;
  }
}

.ie {
  display: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      max-width: 640px;
      text-align: center;
    }
    svg {
      position: absolute;
      top: 50px;
      left: 50px;
    }
  }
}

@import './print';
