@import '../../../styles/variables';
.root {
  img {
    width: 100%;
    max-width: 650px;
  }
  p {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  > p {
    margin-bottom: 2rem;
  }

  .video {
    @include paddingBottom;
    padding-top: 2rem;
    > div {
      padding-bottom: $padding;
    }
    > span {
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 140%;
      opacity: 0.5;
    }
  }

  ul {
    list-style: lower-alpha;

    li {
      display: list-item;
      list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='8' viewBox='-1 -1 2 3' ><circle r='1' /></svg>");
    }
  }
}
