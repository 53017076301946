@import './variables';

.textImageCard:nth-of-type(even) {
  @media screen and (min-width: $desktopBreakpoint) {
    .left {
      order: 2;
    }
    .right {
      order: 1;
    }
  }
}

/*
  ArticlePreview Start
*/

/*
  ArticlePreview End
*/

.text-over-video {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 4.5rem;
  line-height: 140%;

  @media screen and (max-width: $desktopBreakpoint) {
    font-size: 2.5rem;
  }
}

.plug {
  &:first-of-type {
    &.listInfoSplitView {
      margin-top: 70px;
    }
  }
  &:not(:first-of-type) {
    &.headerWithBody {
      > div {
        > div {
          > div {
            padding: 0;
            @include marginBottom;
          }
        }
      }
      h4 {
        font-family: 'Lato', sans-serif;
        text-transform: uppercase;
        font-weight: 300;
      }

      h4,
      p {
        font-size: 2.2rem;
      }

      ul {
        li {
          font-size: 1.6rem;
          line-height: 2.5rem;
        }
      }
    }
  }
}

.quoteWithImage {
  @include marginBottom;
}

.banner {
  @include marginBottom;
}

// gallery for mobile
.rsis-image {
  background-size: cover !important;
}
