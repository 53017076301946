@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.8rem;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: $font-size;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.2rem;
  transition: all 250ms ease-out;
  a {
    text-decoration: inherit;
  }
}
